import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { comparer, reaction } from 'mobx'
import clsx from 'clsx'
import { getUnixTime } from 'date-fns'

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons'

import { DateTimeRangePicker } from '../../DateTimeRangePicker'
import { OzonPreviewPrintTable } from '../../OzonPreviewPrintTable'
import { PrintMsBarcodesCheckbox } from '../../PrintMsBarcodesCheckbox'
import { LabelsPostDateFilters, maxShipmentDate, minShipmentDate } from '../../LabelsPostDateFilters'

import { useApi, useLogic } from '../../../hooks/storeHook'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useCheckboxStyles } from '../../../hooks/checkboxStylesHook'
import { useBoolState, useLazyEffect } from '../../../hooks/commonHooks'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'

import { MemoryTableData } from '../../../store/memoryTableData'

import { exact } from '../../../common/tsUtils'
import { dateUtils } from '../../../common/dateTimeUtility'
import { filterPreviewByShipmentDate, getOrderShipmentDateCounts, OrderShipmentInfo } from '../../../common/labelsUtil'

import { OzonAccountIdProps } from '../../../types/accountProps'
import { OzonPrintOrderColumnId } from '../../../types/ozonColumns'
import { ResponseCancelledError } from '../../../types/responseCancelledError'

import { OzonLabelFormat } from '../../../server/mpsklad_common/Models/OzonLabelFormat'
import { OzonPrintOptions } from '../../../server/mpsklad_core/Models/OzonPrintOptions'
import { OzonPrintOrderModel } from '../../../server/mpsklad_core/Models/OzonPrintOrderModel'
import { OzonLabelsFormat } from '../../../server/mpsklad_core/Models/ApiModels/OzonLabelsFormat'
import { useRadioGroupStyles } from '../../../hooks/radioGroupStylesHook'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DefaultRadioGroup } from '../../DefaultRadioGroup'

export type LabelsTabStateBase = {
  startDate: Date
  endDate: Date
  maxDate: Date

  pickedShipmentDate: Date | null
  shipmentDateMin: Date
  shipmentDateMax: Date

  useAutoReset: boolean
  hasPurchaseList: boolean
  hasMsBarcodes: boolean
  labelsFormat: OzonLabelsFormat
}

export type OzonLabelsTabOptions =
  LabelsTabStateBase & {
  hasOrderNumbers: boolean
  format: OzonLabelFormat

  readonly printRequestData: Readonly<OzonPrintOptions>
  readonly previewRequestData: Readonly<OzonPrintOptions>
}

export const OzonLabelsPrintForm =
  observer(
    ({ozonAccountId}: OzonAccountIdProps) => {
      const api = useApi()
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()
      const [submitError] = useState(null as string | null)

      const radioGroupClasses = useRadioGroupStyles()

      const getLabelsFormatClasses =
        (checked: boolean) =>
          clsx(radioGroupClasses.radioButton, labelsClasses.formatLabels,
            checked && radioGroupClasses.selectedRadioButton)

      const labelsClasses = useStyles()
      const classes = useSettingsPageStyles()
      const checkboxClasses = useCheckboxStyles()

      const labelsFormatOptions =
        useMemo(() => [{
          value: OzonLabelsFormat.SmallLabel,
          label: 'Маленькая (Новая)'
        }, {
          value: OzonLabelsFormat.BigLabel,
          label: 'Большая'
        }], [])

      const options =
        useLocalObservable(() =>
          exact<OzonLabelsTabOptions>({
            // NOTE: Effects are required for initial values that depend on props or state
            startDate: dateUtils.startOfYesterday,
            endDate: dateUtils.endOfToday,
            maxDate: dateUtils.endOfToday,
            // TODO: Not only this is not controlled, its computation is non-trivial...
            pickedShipmentDate: null,
            shipmentDateMin: minShipmentDate,
            shipmentDateMax: maxShipmentDate,
            useAutoReset: false,
            hasPurchaseList: true,
            hasOrderNumbers: true,
            hasMsBarcodes: false,
            labelsFormat: OzonLabelsFormat.BigLabel,
            format: OzonLabelFormat.AmountNameCode,
            get printRequestData() {
              return exact<OzonPrintOptions>({
                accountId: ozonAccountId,
                startDateUnix: getUnixTime(this.startDate),
                endDateUnix: getUnixTime(this.endDate),
                shipmentDateMin: this.shipmentDateMin.toISOString(),
                shipmentDateMax: this.shipmentDateMax.toISOString(),
                useAutoReset: this.useAutoReset,
                hasPurchaseList: this.hasPurchaseList,
                hasOrderNumbers: this.hasOrderNumbers,
                hasMsBarcodes: this.hasMsBarcodes,
                apiLabelsFormat: this.labelsFormat,
                format: this.format
              })
            },
            get previewRequestData(): Readonly<OzonPrintOptions> {
              return exact<OzonPrintOptions>({
                ...this.printRequestData,
                // TODO: Make these filters server-side for absolute consistency
                // TODO: Use startOfDay, endOfDay in Unix-time to preserve client-side timezone on the backend
                // These filters are applied client-side for the preview, thus we request with default values
                shipmentDateMin: minShipmentDate.toISOString(),
                shipmentDateMax: maxShipmentDate.toISOString()
              })
            }
          }))

      const localState =
        useLocalObservable(() => ({
          preview: null as OzonPrintOrderModel[] | null,
          previewWithShipmentFilters: null as OzonPrintOrderModel[] | null,
          get shipmentDateInfo(): OrderShipmentInfo {
            return getOrderShipmentDateCounts(this.preview)
          }
        }))

      const [ozonPrintOrders] = useState(
        () => new MemoryTableData<OzonPrintOrderModel, OzonPrintOrderColumnId>('ozonPrintOrders'))

      // Fetch preview orders from backend when options change
      useLazyEffect(() =>
        reaction(
          () => options.previewRequestData,
          async () => {
            ozonPrintOrders.data = []

            try {
              localState.preview = null
              localState.preview = await api.previewOzonLabelsPreemptive(options.previewRequestData)
              ozonPrintOrders.data = localState.preview ?? []

              // Reset filter by shipment date when other options change
              // TODO: This is required because LabelsPostDateFilters is not controlled, make it
              options.pickedShipmentDate = null
            } catch (e) {
              if (e instanceof ResponseCancelledError) {
                // Ignore
                return
              } else {
                throw e
              }
            }
          }, {
            fireImmediately: true,
            equals: comparer.shallow
          }))

      const updatePreviewWithFilters = useCallback(() => {
        const {previewWithShipmentFilters, shipmentDateMin, shipmentDateMax} =
          filterPreviewByShipmentDate(options.pickedShipmentDate, localState.preview,
            localState.shipmentDateInfo.daysCounts)

        localState.previewWithShipmentFilters = previewWithShipmentFilters
        options.shipmentDateMin = shipmentDateMin
        options.shipmentDateMax = shipmentDateMax

        ozonPrintOrders.data = localState.previewWithShipmentFilters ?? []
      }, [localState, options, ozonPrintOrders])

      useEffect(() => {
        reaction(
          () => options.pickedShipmentDate,
          () => updatePreviewWithFilters())
      }, [options.pickedShipmentDate, updatePreviewWithFilters])

      const onDateChange =
        (startDate: Date, endDate: Date) => {
          options.startDate = startDate
          options.endDate = endDate
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()

          try {
            const {purchaseListFileUrl, errorCount, labelsFileUrl} = await api.label.printOzon(options.printRequestData)

            if (errorCount === 0) {
              showSuccess('Создано!')
            } else {
              showSuccess(`Создано! Отправлений с ошибками: ${errorCount}.`)
            }

            let zipName = 'ozon_labels'
            const fileUrls = [labelsFileUrl]

            // TODO: Don't zip if there is only one file?
            if (purchaseListFileUrl != null) {
              fileUrls.push(purchaseListFileUrl)
              zipName += '_with_purchaseList'
            }

            await logic.downloadFilesAsZip(fileUrls, zipName)
          } finally {
            setSubmitted()
          }
        }

      const onPostDateChange =
        (newDate: Date | null) =>
          options.pickedShipmentDate = newDate

      return (
        <Box className={clsx(classes.pageContainer, classes.fullWidth)} paddingTop={0}>
          <FormHelperText className={classes.helperText}>
            {/* TODO: Take from backend? */}
            Печать этикеток возможна только для заказов в статусе "Ожидает отгрузки".
            Возможна печать до 300 заказов в одном файле.
          </FormHelperText>

          <form onSubmit={onSubmit}>
            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column"
              spacing={3}
            >
              <Grid item container xs={12}>
                <Box width={'100%'}>
                  <FormLabel component="legend" className={classes.label}>
                    Дата поступления заказа
                  </FormLabel>

                  <DateTimeRangePicker
                    containerProps={{xs: 7}}
                    startDate={options.startDate}
                    endDate={options.endDate}
                    maxDate={options.maxDate}
                    onChange={onDateChange}
                  />

                  <FormLabel component="legend" className={classes.label}>
                    Дата для поставки в Ozon
                  </FormLabel>

                  <LabelsPostDateFilters
                    overallCount={localState.preview?.length ?? 0}
                    shipmentDateInfo={localState.shipmentDateInfo}
                    onFilter={onPostDateChange}
                  />

                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item container xs={6}>
                    <FormControl component="fieldset">

                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>
                          Что печатать
                        </FormLabel>
                      </Box>

                      <FormGroup>
                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="feedCheckbox"
                              checked
                              disabled
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircleOutline/>}
                            />
                          }
                          label="Лента"
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.hasPurchaseList && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="purchaseListCheckbox"
                              checked={options.hasPurchaseList}
                              onChange={e => options.hasPurchaseList = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Список покупок"
                        />

                        <PrintMsBarcodesCheckbox
                          checked={options.hasMsBarcodes}
                          onChange={checked => options.hasMsBarcodes = checked}
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.useAutoReset && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="autoResetCheckbox"
                              checked={options.useAutoReset}
                              onChange={e => options.useAutoReset = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Использовать автоматическое обнуление"
                        />

                        <FormHelperText>
                          Распечатанные заказы будут вычеркнуты, и в следующий раз будут взяты только новые заказы.
                        </FormHelperText>
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>

                      <Grid item xs={6}>
                        <FormControl component="fieldset">

                          <Box>
                            <FormLabel component="legend" className={classes.label}>
                              <span className={classes.dash}>—</span>
                              Настройка списка покупок
                            </FormLabel>
                          </Box>

                          <FormGroup>
                            <RadioGroup
                              name="formatRadioGroup"
                              value={options.format}
                              onChange={e => options.format = parseInt(e.target.value)}
                            >
                              <FormControlLabel
                                className={clsx(checkboxClasses.checkbox,
                                  options.format === OzonLabelFormat.AmountNameCode && checkboxClasses.checkedCheckbox)}
                                label="Шт. - Имя [КОД]"
                                control={<Radio color="primary"/>}
                                value={OzonLabelFormat.AmountNameCode}
                                disabled={!options.hasPurchaseList}
                              />

                              <FormControlLabel
                                className={clsx(checkboxClasses.checkbox,
                                  options.format === OzonLabelFormat.CodeAmount && checkboxClasses.checkedCheckbox)}
                                label="КОД - шт."
                                control={<Radio color="primary"/>}
                                value={OzonLabelFormat.CodeAmount}
                                disabled={!options.hasPurchaseList}
                              />
                            </RadioGroup>

                            <FormControlLabel
                              className={clsx(checkboxClasses.checkbox,
                                options.hasOrderNumbers && checkboxClasses.checkedCheckbox)}
                              control={
                                <Checkbox
                                  color="primary"
                                  name="includeOrderNumberCheckbox"
                                  checked={options.hasOrderNumbers}
                                  onChange={e => options.hasOrderNumbers = e.target.checked}
                                  icon={<CircleUnchecked/>}
                                  checkedIcon={<CheckCircle/>}
                                />
                              }
                              label="Вставлять номера заказов"
                              disabled={!options.hasPurchaseList}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>

                        <DefaultRadioGroup
                          label="Формат этикеток"
                          options={labelsFormatOptions}
                          value={options.labelsFormat}
                          onChange={e => options.labelsFormat = e}
                          getOptionLabelClassName={() => radioGroupClasses.radioLabel}
                          getOptionClassName={getLabelsFormatClasses}
                          name="LabelsType"
                        />

                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>

            <Box>
              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isSubmitting}
                onClick={onSubmit}>
                <p>{isSubmitting ? 'Создаём...' : 'Создать этикетки'}</p>
              </button>
            </Box>

            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column">
              {
                !!submitError &&
                <Grid item>
                  <FormLabel error>
                    {submitError}
                  </FormLabel>
                </Grid>
              }

              <Grid item xl={12}>
                <Box>
                  <FormLabel component="legend" className={clsx(classes.label)}>
                    <span className={classes.dash}>—</span>
                    Предпросмотр
                  </FormLabel>
                </Box>

                <OzonPreviewPrintTable orders={ozonPrintOrders}/>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    formatLabels: {
      marginBottom: 20
    }
  }))