import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { comparer, reaction } from 'mobx'
import { getUnixTime } from 'date-fns'

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons'

import { useApi, useLogic } from '../../../hooks/storeHook'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../../../hooks/commonHooks'
import { useCheckboxStyles } from '../../../hooks/checkboxStylesHook'

import { exact } from '../../../common/tsUtils'
import { dateUtils } from '../../../common/dateTimeUtility'
import clsx from 'clsx'

import { YandexMarketAccountIdProps } from '../../../types/accountProps'
import { ResponseCancelledError } from '../../../types/responseCancelledError'
import { YandexMarketPrintOptions } from '../../../server/mpsklad_core/Models/YandexMarketPrintOptions'
import { YandexMarketPrintOrderModel } from '../../../server/mpsklad_core/Models/YandexMarketPrintOrderModel'
import { YandexMarketPrintOrderColumnId } from '../../../types/yandexMarketColumns'
import { MemoryTableData } from '../../../store/memoryTableData'

import { PrintMsBarcodesCheckbox } from '../../PrintMsBarcodesCheckbox'
import { LabelsPostDateFilters, maxShipmentDate, minShipmentDate } from '../../LabelsPostDateFilters'
import { DateTimeRangePicker } from '../../DateTimeRangePicker'
import { YandexMarketPreviewPrintTable } from '../../YandexMarketPreviewPrintTable'

import { filterPreviewByShipmentDate, getOrderShipmentDateCounts, OrderShipmentInfo } from '../../../common/labelsUtil'
import { YandexMarketPageFormatType } from '../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketPageFormatType'
import { DefaultRadioGroup } from '../../DefaultRadioGroup'
import { useRadioGroupStyles } from '../../../hooks/radioGroupStylesHook'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'

export type LabelsTabStateBase = {
  startDate: Date
  endDate: Date
  maxDate: Date

  pickedShipmentDate: Date | null
  shipmentDateMin: Date
  shipmentDateMax: Date

  useAutoReset: boolean
  hasPurchaseList: boolean
  hasMsBarcodes: boolean
  labelsFormat: YandexMarketPageFormatType
}

export type YandexMarketLabelsTabState =
  LabelsTabStateBase & {
  readonly printRequestData: Readonly<YandexMarketPrintOptions>
  readonly previewRequestData: Readonly<YandexMarketPrintOptions>
}

export const YandexMarketLabelsPrintForm =
  observer(
    ({yandexMarketAccountId}: YandexMarketAccountIdProps) => {
      const api = useApi()
      const logic = useLogic()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()
      const [submitError] = useState(null as string | null)

      const radioGroupClasses = useRadioGroupStyles()

      const getLabelsFormatClasses =
        (checked: boolean) =>
          clsx(radioGroupClasses.radioButton, labelsClasses.formatLabels,
            checked && radioGroupClasses.selectedRadioButton)

      const labelsClasses = useStyles()
      const classes = useSettingsPageStyles()
      const checkboxClasses = useCheckboxStyles()

      const options =
        useLocalObservable(() =>
          exact<YandexMarketLabelsTabState>({
            // NOTE: Effects are required for initial values that depend on props or state
            startDate: dateUtils.startOfYesterday,
            endDate: dateUtils.endOfToday,
            maxDate: dateUtils.endOfToday,
            // TODO: Not only this is not controlled, its computation is non-trivial...
            pickedShipmentDate: null,
            shipmentDateMin: minShipmentDate,
            shipmentDateMax: maxShipmentDate,
            useAutoReset: false,
            hasPurchaseList: true,
            hasMsBarcodes: false,
            labelsFormat: YandexMarketPageFormatType.A9,
            get printRequestData() {
              return exact<YandexMarketPrintOptions>({
                accountId: yandexMarketAccountId,
                startDateUnix: getUnixTime(this.startDate),
                endDateUnix: getUnixTime(this.endDate),
                shipmentDateMin: this.shipmentDateMin.toISOString(),
                shipmentDateMax: this.shipmentDateMax.toISOString(),
                useAutoReset: this.useAutoReset,
                hasPurchaseList: this.hasPurchaseList,
                hasMsBarcodes: this.hasMsBarcodes,
                labelsFormat: this.labelsFormat
              })
            },
            get previewRequestData(): Readonly<YandexMarketPrintOptions> {
              return exact<YandexMarketPrintOptions>({
                ...this.printRequestData,
                // TODO: Make these filters server-side for absolute consistency
                // TODO: Use startOfDay, endOfDay in Unix-time to preserve client-side timezone on the backend
                // These filters are applied client-side for the preview, thus we request with default values
                shipmentDateMin: minShipmentDate.toISOString(),
                shipmentDateMax: maxShipmentDate.toISOString()
              })
            }
          }))

      const localState =
        useLocalObservable(() => ({
          preview: null as YandexMarketPrintOrderModel[] | null,
          previewWithShipmentFilters: null as YandexMarketPrintOrderModel[] | null,
          get shipmentDateInfo(): OrderShipmentInfo {
            return getOrderShipmentDateCounts(this.preview)
          }
        }))

      const [yandexMarketPrintOrders] = useState(
        () => new MemoryTableData<YandexMarketPrintOrderModel, YandexMarketPrintOrderColumnId>(
          'yandexMarketPrintOrders'))

      // Fetch preview orders from backend when options change
      useLazyEffect(() =>
        reaction(
          () => options.previewRequestData,
          async () => {
            yandexMarketPrintOrders.data = []

            try {
              localState.preview = null
              localState.preview = await api.previewYandexMarketLabelsPreemptive(options.previewRequestData)
              yandexMarketPrintOrders.data = localState.preview ?? []

              // Reset filter by shipment date when other options change
              // TODO: This is required because LabelsPostDateFilters is not controlled, make it
              options.pickedShipmentDate = null
            } catch (e) {
              if (e instanceof ResponseCancelledError) {
                // Ignore
                return
              } else {
                throw e
              }
            }
          }, {
            fireImmediately: true,
            equals: comparer.shallow
          }))

      const updatePreviewWithFilters = useCallback(() => {
        const {previewWithShipmentFilters, shipmentDateMin, shipmentDateMax} =
          filterPreviewByShipmentDate(options.pickedShipmentDate, localState.preview,
            localState.shipmentDateInfo.daysCounts)

        localState.previewWithShipmentFilters = previewWithShipmentFilters
        options.shipmentDateMin = shipmentDateMin
        options.shipmentDateMax = shipmentDateMax

        yandexMarketPrintOrders.data = localState.previewWithShipmentFilters ?? []
      }, [localState, options, yandexMarketPrintOrders])

      useEffect(() => {
        reaction(
          () => options.pickedShipmentDate,
          () => updatePreviewWithFilters())
      }, [options.pickedShipmentDate, updatePreviewWithFilters])

      const labelsFormatOptions =
        useMemo(() => [{
          value: YandexMarketPageFormatType.A9,
          label: 'A9',
          hint: 'На каждой странице размещается ярлык размером 40 × 58 мм без полей. Только для продавцов из России.'
        }, {
          value: YandexMarketPageFormatType.A9_HORIZONTALLY,
          label: 'A9 (горизонтально)',
          hint: 'На каждой странице размещается ярлык размером 58 × 40 мм без полей. Только для продавцов из России.'
        }, {
          value: YandexMarketPageFormatType.A7,
          label: 'A7',
          hint: 'На каждой странице размещается ярлык размером 120 × 75 мм (125,6 × 80,4 мм с учётом полей).'
        }], [])

      const onDateChange =
        (startDate: Date, endDate: Date) => {
          options.startDate = startDate
          options.endDate = endDate
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()

          try {
            const {purchaseListFileUrl, errorCount, labelsFileUrl} = await api.label.printYandexMarket(
              options.printRequestData)

            if (errorCount === 0) {
              showSuccess('Создано!')
            } else {
              showSuccess(`Создано! Отправлений с ошибками: ${errorCount}.`)
            }

            let zipName = 'yandex_market_labels'
            const fileUrls = [labelsFileUrl]

            // TODO: Don't zip if there is only one file?
            if (purchaseListFileUrl != null) {
              fileUrls.push(purchaseListFileUrl)
              zipName += '_with_purchaseList'
            }

            await logic.downloadFilesAsZip(fileUrls, zipName)
          } finally {
            setSubmitted()
          }
        }

      const onPostDateChange =
        (newDate: Date | null) =>
          options.pickedShipmentDate = newDate

      return (
        <Box className={clsx(classes.pageContainer, classes.fullWidth)} padding={2} paddingTop={0}>
          <FormHelperText className={classes.helperText}>
            {/* TODO: Take from backend? */}
            Печать этикеток возможна только для заказов в статусе "Ожидает отгрузки".
            Возможна печать до 300 заказов в одном файле.
          </FormHelperText>

          <form onSubmit={onSubmit}>
            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column"
              xs={12}
              spacing={3}
            >
              <Grid item container xs={12}>
                <Box width={'100%'}>
                  <FormLabel component="legend" className={classes.label}>
                    Дата поступления заказа
                  </FormLabel>

                  <DateTimeRangePicker
                    containerProps={{xs: 7}}
                    startDate={options.startDate}
                    endDate={options.endDate}
                    maxDate={options.maxDate}
                    onChange={onDateChange}
                  />

                  <FormLabel component="legend" className={classes.label}>
                    Дата для поставки в YandexMarket
                  </FormLabel>

                  <LabelsPostDateFilters
                    overallCount={localState.preview?.length ?? 0}
                    shipmentDateInfo={localState.shipmentDateInfo}
                    onFilter={onPostDateChange}
                  />

                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">

                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>
                          Что печатать
                        </FormLabel>
                      </Box>

                      <FormGroup>
                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="feedCheckbox"
                              checked
                              disabled
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircleOutline/>}
                            />
                          }
                          label="Лента"
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.hasPurchaseList && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="purchaseListCheckbox"
                              checked={options.hasPurchaseList}
                              onChange={e => options.hasPurchaseList = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Список покупок"
                        />

                        <PrintMsBarcodesCheckbox
                          checked={options.hasMsBarcodes}
                          onChange={checked => options.hasMsBarcodes = checked}
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.useAutoReset && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="autoResetCheckbox"
                              checked={options.useAutoReset}
                              onChange={e => options.useAutoReset = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Использовать автоматическое обнуление"
                        />

                        <FormHelperText>
                          Распечатанные заказы будут вычеркнуты, и в следующий раз будут взяты только новые заказы.
                        </FormHelperText>
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <DefaultRadioGroup
                      label="Формат этикеток"
                      options={labelsFormatOptions}
                      value={options.labelsFormat}
                      onChange={e => options.labelsFormat = e}
                      getOptionLabelClassName={() => radioGroupClasses.radioLabel}
                      getOptionClassName={getLabelsFormatClasses}
                      name="LabelsType"
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

            <Box>
              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isSubmitting}
                onClick={onSubmit}>
                <p>{isSubmitting ? 'Создаём...' : 'Создать этикетки'}</p>
              </button>
            </Box>

            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column">
              {
                !!submitError &&
                <Grid item>
                  <FormLabel error>
                    {submitError}
                  </FormLabel>
                </Grid>
              }

              <Grid item xl={12}>
                <Box>
                  <FormLabel component="legend" className={clsx(classes.label, classes.label)}>
                    <span className={classes.dash}>—</span>
                    Предпросмотр
                  </FormLabel>
                </Box>

                <YandexMarketPreviewPrintTable orders={yandexMarketPrintOrders}/>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    formatLabels: {
      marginBottom: 20
    }
  }))