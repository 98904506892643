//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { OzonPrintOrderModel } from '../Models/OzonPrintOrderModel';
import { OzonPrintOptions } from '../Models/OzonPrintOptions';
import { PrintResult } from '../Models/PrintResult';
import { WbPrintOrderModel } from '../Models/WbPrintOrderModel';
import { WbPrintOptions } from '../Models/WbPrintOptions';
import { YandexMarketPrintOrderModel } from '../Models/YandexMarketPrintOrderModel';
import { YandexMarketPrintOptions } from '../Models/YandexMarketPrintOptions';
import { PrintFileModel } from '../Models/PrintFileModel';
import { WbPrintFileModel } from '../Models/WbPrintFileModel';
import { YandexMarketPrintFileModel } from '../Models/YandexMarketPrintFileModel';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class LabelController
{
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  
  private readonly axios: AxiosInstance
  
  previewOzon(options: OzonPrintOptions, _config?: AxiosRequestConfig) : Promise<OzonPrintOrderModel[]>
  {
    return this.axios.post<OzonPrintOrderModel[]>(`Label/PreviewOzon`, options, _config).then(getAxiosData)
  }
  

  printOzon(options: OzonPrintOptions, _config?: AxiosRequestConfig) : Promise<PrintResult>
  {
    return this.axios.post<PrintResult>(`Label/PrintOzon`, options, _config).then(getAxiosData)
  }
  

  previewWbSupply(options: WbPrintOptions, _config?: AxiosRequestConfig) : Promise<WbPrintOrderModel[]>
  {
    return this.axios.post<WbPrintOrderModel[]>(`Label/PreviewWbSupply`, options, _config).then(getAxiosData)
  }
  

  printWbSupply(options: WbPrintOptions, _config?: AxiosRequestConfig) : Promise<PrintResult>
  {
    return this.axios.post<PrintResult>(`Label/PrintWbSupply`, options, _config).then(getAxiosData)
  }
  

  previewYandexMarket(options: YandexMarketPrintOptions, _config?: AxiosRequestConfig) : Promise<YandexMarketPrintOrderModel[]>
  {
    return this.axios.post<YandexMarketPrintOrderModel[]>(`Label/PreviewYandexMarket`, options, _config).then(getAxiosData)
  }
  

  printYandexMarket(options: YandexMarketPrintOptions, _config?: AxiosRequestConfig) : Promise<PrintResult>
  {
    return this.axios.post<PrintResult>(`Label/PrintYandexMarket`, options, _config).then(getAxiosData)
  }
  

  ozonFiles(ozonAccountId: number, _config?: AxiosRequestConfig) : Promise<PrintFileModel[]>
  {
    return this.axios.get<PrintFileModel[]>(`Label/OzonFiles/${encodeUrlParameter(ozonAccountId)}`, _config).then(getAxiosData)
  }
  

  wbFiles(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<WbPrintFileModel[]>
  {
    return this.axios.get<WbPrintFileModel[]>(`Label/WbFiles/${encodeUrlParameter(wbAccountId)}`, _config).then(getAxiosData)
  }
  

  yandexMarketFiles(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : Promise<YandexMarketPrintFileModel[]>
  {
    return this.axios.get<YandexMarketPrintFileModel[]>(`Label/YandexMarketFiles/${encodeUrlParameter(yandexMarketAccountId)}`, _config).then(getAxiosData)
  }
  

  ozonLabelsFile(labelsFileId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileStreamResult*/>(`Label/OzonLabelsFile/${encodeUrlParameter(labelsFileId)}`, _config)
  }
  

  ozonPurchaseListFile(purchaseListFileId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileStreamResult*/>(`Label/OzonPurchaseListFile/${encodeUrlParameter(purchaseListFileId)}`, _config)
  }
  

  wbLabelsFile(labelsFileId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileStreamResult*/>(`Label/WbLabelsFile/${encodeUrlParameter(labelsFileId)}`, _config)
  }
  

  wbPurchaseListFile(purchaseListFileId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileStreamResult*/>(`Label/WbPurchaseListFile/${encodeUrlParameter(purchaseListFileId)}`, _config)
  }
  

  yandexMarketLabelsFile(labelsFileId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileStreamResult*/>(`Label/YandexMarketLabelsFile/${encodeUrlParameter(labelsFileId)}`, _config)
  }
  

  yandexMarketPurchaseListFile(purchaseListFileId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileStreamResult*/>(`Label/YandexMarketPurchaseListFile/${encodeUrlParameter(purchaseListFileId)}`, _config)
  }
  

  moySkladOrder(labelLinkId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`/ms-order-label/${encodeUrlParameter(labelLinkId)}`, _config)
  }
  

  moySkladOrderSupply(labelLinkId: string, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`/ms-order-supply-label/${encodeUrlParameter(labelLinkId)}`, _config)
  }
  

  /** Gets the full relative URL for the action */
  ozonFilesUrl(ozonAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/OzonFiles/${encodeUrlParameter(ozonAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  wbFilesUrl(wbAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/WbFiles/${encodeUrlParameter(wbAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  yandexMarketFilesUrl(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/YandexMarketFiles/${encodeUrlParameter(yandexMarketAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  ozonLabelsFileUrl(labelsFileId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/OzonLabelsFile/${encodeUrlParameter(labelsFileId)}`
  }
  

  /** Gets the full relative URL for the action */
  ozonPurchaseListFileUrl(purchaseListFileId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/OzonPurchaseListFile/${encodeUrlParameter(purchaseListFileId)}`
  }
  

  /** Gets the full relative URL for the action */
  wbLabelsFileUrl(labelsFileId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/WbLabelsFile/${encodeUrlParameter(labelsFileId)}`
  }
  

  /** Gets the full relative URL for the action */
  wbPurchaseListFileUrl(purchaseListFileId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/WbPurchaseListFile/${encodeUrlParameter(purchaseListFileId)}`
  }
  

  /** Gets the full relative URL for the action */
  yandexMarketLabelsFileUrl(labelsFileId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/YandexMarketLabelsFile/${encodeUrlParameter(labelsFileId)}`
  }
  

  /** Gets the full relative URL for the action */
  yandexMarketPurchaseListFileUrl(purchaseListFileId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/Label/YandexMarketPurchaseListFile/${encodeUrlParameter(purchaseListFileId)}`
  }
  

  /** Gets the full relative URL for the action */
  moySkladOrderUrl(labelLinkId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/ms-order-label/${encodeUrlParameter(labelLinkId)}`
  }
  

  /** Gets the full relative URL for the action */
  moySkladOrderSupplyUrl(labelLinkId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/ms-order-supply-label/${encodeUrlParameter(labelLinkId)}`
  }
  

}
