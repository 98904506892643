import React from 'react'

import { Link, TableCell } from '@material-ui/core'

import { formatShipmentDatesRange } from '../common/dateTimeUtility'

import { MyRowProps } from '../types/tableTypes'
import { PrintFileModel } from '../server/mpsklad_core/Models/PrintFileModel'

export type PrintFileCellProps =
  MyRowProps<PrintFileModel> & {
  makeLabelsUrl: (fileId: string) => string

  makePurchaseListUrl: (fileId: string) => string
}

const purchaseListStartDateUnix = 1734440640 /* 17-Dec-24 at 16:04 */

export const PrintFileCell =
  ({row, makeLabelsUrl, makePurchaseListUrl}: PrintFileCellProps) => {
    const purchaseListUrl = makePurchaseListUrl(row.id)

    return (
      <TableCell className="table-border">
        <Link href={makeLabelsUrl(row.id)} target="_blank" underline="always">
          Этикетки от {formatShipmentDatesRange(row)}
        </Link>

        {
          row.createdAt > purchaseListStartDateUnix &&
          <>
            <br/>
            <Link href={purchaseListUrl} target="_blank" underline="always">
              Лист подбора от {formatShipmentDatesRange(row)}
            </Link>
          </>
        }
      </TableCell>
    )
  }