import React from 'react'

import { PrintFileCell } from './PrintFileCell'

import { useApi } from '../hooks/storeHook'

import { MyRowProps } from '../types/tableTypes'
import { PrintFileModel } from '../server/mpsklad_core/Models/PrintFileModel'

export const OzonFileCell =
  ({row}: MyRowProps<PrintFileModel>) => {
    const api = useApi()

    return <PrintFileCell
      row={row}
      makeLabelsUrl={api.label.ozonLabelsFileUrl}
      makePurchaseListUrl={api.label.ozonPurchaseListFileUrl}/>
  }