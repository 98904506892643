//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

/** Тип размещение ярлыков на странице */
export enum YandexMarketPageFormatType {
  /**
  * в PDF-файле будут страницы формата, близкому к A9. На каждой странице размещается ярлык размером 58 × 40 мм без
  *               полей. Доступен только для продавцов из России.
  */
  A9_HORIZONTALLY = 0,
  /**
  * в PDF-файле будут страницы формата, близкому к A9. На каждой странице размещается ярлык размером 40 × 58 мм без
  *               полей. Доступен только для продавцов из России.
  */
  A9 = 1,
  /**
  * в PDF-файле будут страницы формата, близкому к A7. На каждой странице размещается ярлык размером 120 × 75 мм (125,6 ×
  *               80,4 мм с учетом полей).
  */
  A7 = 2,
  /**
  * в PDF-файле будут страницы формата A4. На каждой странице размещаются восемь ярлыков размером 99,1 × 70,6 мм без
  *               полей.
  */
  A4 = 3
}
